import { h, Component } from 'preact';
import { Router, Route, Link, route } from 'preact-router';

import { Themes, Stations } from '../config';

// Code-splitting is automated for routes
import Player from '../routes/player';

const mapboxgl = (global || window).mapboxgl || {};

mapboxgl.accessToken = 'pk.eyJ1Ijoic3R1ZGVudHJhZGlvIiwiYSI6ImNrZDFuYzFwcDE1Y24zMnB2ZXd5czl3Z3EifQ.euyjh-GCJbgUyGemGXkV0A';

class Wrap extends Component {
	render (props) {
		return props.render(props)
	}
}

console.log('router', Router.subscribers)

Router.subscribers.push(function (url) {
	if (!url.startsWith('/player')) {
		throw new Error('bad route')
	}
})

export default class App extends Component {

	state = {
		stations: [],
		geoSortedStations: [],
		modal: true,
		loading: true,
		canPlay: false
	}
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
		console.log('got route!', e, window.event?.target?.closest('.play-button'))

		if (window.event?.target?.closest('.play-button')) {
			this.app && this.app.scrollIntoView({ behaviour: 'smooth', block: 'start', inline: 'nearest' });
		}
	};

	async componentDidMount () {

		if (this.mapContainer) {
			this.tryInitMap();
		}

		this.setState({
			loading: document.readyState !== 'complete'
		})

		window.addEventListener('load', () => {
			this.setState({
				loading: false
			}, () => this.forceUpdate())
		})

		// console.log('made map', this.map, (window.innerWidth / window.innerHeight) * 2, Stations)

		try {
			this.setState({
				stations: (await Stations.getStations()).stations,
				canPlay: true
			}, () => {
				console.log('le loc test', Stations)
				if (Stations._location) {
					console.log('le location', Stations._location)
					this.setState({
						geoSortedStations: Stations.geosort(this.state.stations, Stations._location)
					})

					this.mapContainer && this.addStationMarkers();
				}
			})
			// console.log('!!!', (await Stations.getStations()).stations)
		} catch (e) {
			this.setState({
				canPlay: false
			})
		}		

		window.addEventListener('scroll', this._onScroll, true)

	}

	async tryInitMap () {
		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/studentradio/ckd1ndorp25j61jmr6r2ejj5z',
			center: [(5 - window.innerWidth / window.innerHeight) * 1.3 - 10.9278, 54.5074],
			zoom: 5
		})

		this.map.scrollZoom.disable();
		this.map.touchZoomRotate.disable();

		this.map.once('render', () => this.map.resize())

		if (this.state.geoSortedStations) {
			this.addStationMarkers();
		}
	}

	componentWillUnmount () {
		window.removeEventListener('scroll', this._onScroll, true)
	}

	addStationMarkers () {
		this.state.stations.filter((station) => station.location && station.location.length == 2).forEach((station) => {
			let el = document.querySelector("#marker-" + station.ref) || document.createElement('div')

			el.className = 'map-station-marker';

			//if (!el.id) {
				el.id = 'marker-' + station.ref;
				new mapboxgl.Marker(el).setLngLat(station.location).addTo(this.map)
			//}
		})
	}

	_clickStation (station) {
		route('/player/station/' + station.ref)

		this.map.flyTo({
			center: station.location,
			zoom: 11,
			bearing: 0,
			speed: 3,
			curve: 1,
			easing: (t) => t,
			essential: true
		})
	}

	async getLocation () {
		let location = (await new Promise (resolve => navigator.geolocation.getCurrentPosition(resolve))).coords;

		// console.log('got loc', location)
		let stations = Stations.geosort(this.state.stations, location);

		this.setState({
			location,
			stations,
			geoSortedStations: stations.slice(0).sort((a, b) => a.distance - b.distance)
		})
	}

	render (props, { canPlay, stations, geoSortedStations }) {
		let station;

		return (
			<div id="app" ref={ (r) => this.app = r }>

				{ this.state.loading && (
					<div key="l" class="about loading">
						<h1>Sit tight, loading...</h1>
					</div>
				)}

				<Router key="r" onChange={ this.handleRoute }>
					<Wrap path="/player/" render={ this.renderMain } />
					<Wrap path="/player/station/:station" render={ this.renderMain } />
				</Router>
			</div>
		)
	}

	renderSRA = () => {
		return (
			<div key="sra">
				<div className="about">
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Link href="/">
							<img src={ require('../assets/back.png').default } />
						</Link>

						<div style={{ width: 50 }}></div>

						<img src={ require('../assets/sra-logo.png').default } style={{ height: 120 }} />
					</div>
					<h1 style={{ color: '#fff' }}>The Student Radio Association</h1>

					<p>{"The UK Student Radio Association is is an organisation run to enable, encourage and act on behalf of the UK student radio community. We represent radio stations linked to educational establishments, as well as individual students participating in radio and audio projects, and work with industry bodies to offer advice, support and opportunities to our members."}</p>

					<p>{"student.radio is the temporary home of the Student Radio Association."}</p>
				</div>
			</div>
		)
	}

	renderAwards = () => {
		return (
			<div key="awards">
				<div className="about">
					<h1 style={{ color: '#fff', alignItems: 'center', display: 'flex' }}>
						<a href="/" style={{ marginRight: 15 }}>
							<img src={ require('../assets/back.png').default } />
						</a>
						<span>The Student Radio Awards 2020.</span>
					</h1>
					{ /* <h3 style={{ color: '#fff' }}>Supported by BBC Radio 1 and Global.</h3>

					<p>{"The Student Radio Awards are taking place online on Friday the 6th of November."}</p>

					<p>{"To watch the awards, join your SRA regional Facebook group, and follow the event page."}</p>

					<p>
						<a href="https://www.facebook.com/studentradio" target="_blank" class="button">
							&#x2605;&nbsp;&nbsp;&nbsp;Interested
						</a>

						<a href="https://twitter.com/SRA/status/1319337731143667714" target="_blank" class="button">
							See The Nominees
						</a>
					</p> */ }

					<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fstudentradio%2Fvideos%2F361795881598268%2F&show_text=false&width=560" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
				</div>
			</div>
		)
	}

	renderMain = ({ station }) => {

		let { canPlay, stations, geoSortedStations } = this.state;

		console.log('hello world')

		let modal = !window.location.pathname.startsWith('/station');

		let x = (
			<div key="main">
				<Player
					station={ station }
					theme={ Themes.active }
					key="player"
					modal={
						null
					} />

				<div className="station-list" style={{ position: 'relative', height: 'calc(300vh + ' + (60 * (geoSortedStations.concat(stations).length) - 500) + 'px)' }}>

					<div className="station-map">
						<div className="station-map-push" ref={ (r) => this.mapContainer = r }></div>
					</div>
					<div className="station-list-box">
						<h1 id="#stations">Station List</h1>

						<div className="list-content">
							<h3><a href="#near-you" name="near-you">NEAR YOU</a> <a href="" onClick={ (e) => { e.preventDefault(); this.getLocation(); return false; } }>&#x1F4CD;</a></h3>

							<div className="station-list-container">
								{ geoSortedStations.slice(0, 5).map((station) => (
									<div title="View on map" href={"/player/station/" + station.ref } className="station-list-item" onClick={ this._clickStation.bind(this, station) }>
										<a name={ station.ref }></a>
										<span>{ station.name } ({ station.distance | 0 } mil)</span>
										<div className="play-button">
											{
												station.streams?.icecast && 
													<Link title="Play" href={"/player/station/" + station.ref}><i class="fa fa-play-circle"></i></Link>
											}
										</div>
									</div>
								)) }
							</div>

							<h3><a href="#uk" name="uk">UK</a></h3>

							<div className="station-list-container">
								{ stations.map((station) => (
									<div title="View on map" href={"/player/station/" + station.ref } className="station-list-item" onClick={ this._clickStation.bind(this, station) }>
										<a name={ station.ref }></a>
										<span>{ station.name }</span>
										<div className="play-button">
											{
												station.streams?.icecast &&
													<Link title="Play" href={"/player/station/" + station.ref}><i class="fa fa-play-circle"></i></Link>
											}
										</div>
									</div>
								)) }
							</div>
						</div>
					</div>



					<a name="geo"></a>
					<div
						className={ "content-contain " + (this._blur ? 'blur' : '') }
						ref={ (r) => this.blurAnimate(r) }>
						{ !canPlay && (
							<div className="about blur">
								<h1 style={{ color: '#fff' }}>{"We're not available in your region yet."}</h1>

								<p>{"Due to music licensing restrictions, we can't stream stations outside their country. "}</p>
							</div>
						)}

						<div className="about" style={{ height: '200vh' }}>
							<h1 style={{ color: '#fff' }}>The home of Student Radio.</h1>

							<p>{"The Student Radio Player is the ultimate directory of student radio stations. "}</p>

							<div style={{ display: 'flex', width: '100%', margin: '50px 20px', flexWrap: 'wrap' }}>
								<div style={{ flex: 1, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
									<a title="Find out more about " href="https://www.studentradio.org.uk/"><img alt="SRA Logo" src={ require('../assets/sra-logo.png').default } style={{ width: '15vw', marginBottom: '50px', minWidth: '250px' }} /></a>

									<p style={{ maxWidth: 850, textAlign: 'center' }}>{"This website is run by the Student Radio Association. The SRA is the industry body for student radio in the UK. "}</p>
								</div>
								{ /* <div style={{ flex: 1, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
									<img alt="Uniwaves Logo" src={ require('../assets/uniwaves.png').default } style={{ width: '15vw', display: 'block', margin: '0px 0 50px 0', minWidth: '150px' }} />

									<a href="https://apps.apple.com/gb/app/uniwaves/id1478828114" title="Download on App Store"><img src={ require('../assets/app-store.png').default } style={{ width: '180px' }} /></a>
									<a href="https://play.google.com/store/apps/details?id=uk.co.joshuacarlin.uniwaves&hl=en" title=""><img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style={{ width: '200px' }} /></a>

									<p style={{ fontSize: '7pt' }}>Note: The SRA is not responsible for the content of third party apps or links.</p>
								</div> */ }
							</div>

						</div>

						{ /*<div className="about">
							<h1 style={{ color: '#fff' }}>Get Involved.</h1>

							<p>{"Student radio is for everyone - experience or not, your university's radio station would love for you to apply."}</p>

							<p>{"You can find out more information on their website, or through your Students' Union."}</p>

							<p>{"No station at your university? Reach out to the Student Radio Association, if you want to set up a radio station at your University."}</p>
						</div>

						<div className="about">
							<h1 style={{ color: '#fff' }}>Add Your Station.</h1>

							<p>{"Is your station missing? As long as you're a student-led broadcaster, SRA member or not, you can request we list your station. All you need to do is "}<a href="https://forms.gle/Vg48vaQuN3DMbMMdA" target="_blank">{"fill in this form."}</a></p>

							<p>{"You don't have to be based in the UK to add your station. We are, in fact, looking to launch regional versions of student.radio in the future. Due to music licensing, we are unable to make stations available outside the country they broadcast from."}</p>

							<p>{"Do you represent an industry body for student radio, and want to operate student.radio in your country? "}<a href="mailto:tech@student.radio">{"Get in touch."}</a></p>
						</div> */ }

					</div>

				</div>

				
			</div>
		);

		console.log('got x', x)
		return x;
	}

	blurAnimate = (ref) => {
		if (!ref || ref == this._blurRef) {
			return;
		}

		this._blurRef = ref;

		let animation = this._blurRef.animation = this._blurRef.animate(
			[
				{ backdropFilter: 'blur(0)', backgroundColor: 'rgba(0, 0, 0, 0)'},
				{ backdropFilter: 'blur(15px)', backgroundColor: 'rgba(0, 0, 0, 0)'},
				{ backdropFilter: 'blur(20px)', backgroundColor: 'rgba(0, 0, 0, 0.4)'}
			],
			{ duration: 700, fill: 'forwards' }
		)

		animation.pause();
		animation.ready.then(() => animation.commitStyles());
	}

	_onScroll = (event) => {
		if (!this._blurRef) {
			return;
		}

		let elScroll = this._blurRef.getBoundingClientRect().top + 500;

		let frame = this._blurRef.targetVal = Math.max(-500, Math.min(500, elScroll))

		// the effect takes place over 
		this._blurRef.animation.currentTime = 500 - frame;
		this._blurRef.classList[(this._blurred = elScroll < 0) ? 'add' : 'remove']('blur');
	}
}
