import CarRadio from './themes/car-radio'
import slugify from 'slugify';

const Stations = {
	getStations: async () => {
		if (Stations._stations) {
			return await (Stations._stations.then ? Stations._stations : new Promise((resolve) => resolve(Stations._stations)));
		}

		let response = await fetch('https://members.sra.workers.dev/');
		let json = await response.json();

		let stations = json.stations.map((station) => ({
			ref: slugify(station.station, {
				replacement: '-',
				remove: /[*+~.()'"!:@]/g,
				lower: true,
				strict: true
			}),
			name: station.station,
			streams: {
				icecast: station.raw_stream
			},
			location: [station.lng, station.lat],
			now_playing: {

			}
		}))

		let location = json.location;

		if (json.error == 'geo') {
			window.location.hash = '#geo';
			throw new Error('geo');
		}

		let data = {
			version: "1.0-beta",
			stations
		}

		console.log('!! stations', data, json, location)

		Stations._stations = data;
		Stations._location = location;

		return data;
	},


	calculateDistance: (lat1, lon1, lat2, lon2, unit) => {
		var radlat1 = Math.PI * lat1/180
		var radlat2 = Math.PI * lat2/180
		var radlon1 = Math.PI * lon1/180
		var radlon2 = Math.PI * lon2/180
		var theta = lon1-lon2
		var radtheta = Math.PI * theta/180
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		dist = Math.acos(dist)
		dist = dist * 180/Math.PI
		dist = dist * 60 * 1.1515
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	},

	geosort: (stations, location) => {
		stations.map((s) => s.distance = Stations.calculateDistance(location.latitude, location.longitude, s.location[1], s.location[0], 'N'))

		return stations.slice(0).sort((a, b) => a.distance - b.distance)
	}
}

const Themes = {
	car_radio: CarRadio,
}

Themes.active = () => Themes.car_radio;

export { Stations, Themes }