import { h, Component } from 'preact';
import style from './style.css';
import { Link } from 'preact-router';

export default class Theme extends Component {

	state = {
		letters: '            ',
		letterScrollIndex: -2,
		letterScroll: Array(12).fill('')
	}

	componentDidMount () {
		if (!this.timer && !window._debugAnimTimer)
			window._debugAnimTimer = this.timer = setInterval(this.updateLetters.bind(this), 1000)

		this.setState({
			letters: this.props.station.name,
			letterScrollIndex: -1
		})
	}

	componentDidUpdate (prevProps, prevState) {
		prevProps.station.name != this.props.station.name && this.setState({
			letters: this.props.station.name,
			letterScrollIndex: -1
		}, () => this.updateLetters())

		prevProps.loading != this.props.loading && this.updateLetters()

		if (prevProps.error != this.props.error) {
			this.setState({
				letterScrollIndex: -1
			}, () => this.updateLetters())
		}
	}

	updateLetters () {
		let { letterScrollIndex, letterScroll, letters } = this.state;

		letterScroll = letterScroll.slice(0);

		if (this.props.error) {
			/* return this.setState({
				letterScroll: 'UNAVAILABLE     '.split(''),
				letterScrollIndex: 0
			}) */ 

			letters = 'STATION OFF AIR    ';

		} else if (this.props.loading) {
			return this.setState({
				letterScroll: 'LOADING     '.split(''),
				letterScrollIndex: 0
			})
		}

		if (letters.length < letterScroll.length || (!this.state.error && !this.props.on)) {
			if (this.state.letterScrollIndex == 0 && this.state.letterScroll.join('').startsWith(this.state.letters)) {
				return;
			}

			return this.setState({
				letterScroll: letterScroll.map((_, index) => letters[index] || ''),
				letterScrollIndex: 0
			})
		}

		let length = letters.length - 2;
		letterScrollIndex = (length + 1 + letterScrollIndex) % length;


		for (
			var i = 0, j = letterScrollIndex;
			i < letterScroll.length;
			i++, j = (i + letterScrollIndex)
		) {

			letterScroll[i] = letters[j] || '';
		}

		this.setState({
			letterScroll,
			letterScrollIndex
		})
	}

	componentWillUnmount () {
		clearInterval(this.timer)
		this.timer = null;
	}

	render ({ modal }) {

		let slots = Array(12).fill(0), letters = this.state.letterScroll;

		return (
			<div class="theme-root">
				
				<Link href="/">
					<h1 class="main main-logo">
						<span>
							<img src={ require('../../assets/play.png').default } />
							<span>student radio player</span>
						</span>
					</h1>
				</Link>

				{ modal && <div class="modal-site-welcome">
					{ modal }
				</div> || <div class="modal-site-welcome hidden"></div> }

				<div class={ "radio-box playing " + (this.props.on ? '' : ' radio-off') }>
					<div class="layer radio-bg"></div>
					<div class="layer layer-text" title="Station Name" aria-label="Station Name">
						{<table className="letters">
							<tr>
								{ slots.map((_, index) => <td>{ (this.props.on && letters[index]) || ' ' }</td>)}
							</tr>
						</table>}
						{<table className="letters-ghost">
							<tr>
								{ slots.map((_, index) => <td>X</td>)}
							</tr>
						</table>}
						{<table className="letters-ghost">
							<tr>
								{ slots.map((_, index) => <td>O</td>)}
							</tr>
						</table>}
					</div>
					<div class="layer radio-fg"></div>
					<div class="layer a volume" title="Play" aria-label="Play Pause"></div>


					<div class="layer a btn-1"><span></span></div>
					<div class="layer a btn-2"></div>
					<div class="layer a btn-3"></div>
					<div class="layer a btn-4"></div>
					<div class="layer a btn-5"></div>
					<div class="layer a btn-6"></div>


					<div class="layer a btn-left-eq"></div>
					<div class="layer a btn-left-menu"></div>
					<div class="layer a btn-left-mode"></div>
					<div class="layer a btn-left-off" onClick={ this.props.onPower }></div>
					<div class="layer a btn-left-skip-backwards" onClick={ this.props.onSeekPrev } ></div>
					<div class="layer a btn-left-skip-forwards" onClick={ this.props.onSeekNext }></div>
					<div class="layer a btn-left-source"></div>

					<div class="layer a btn-right-eject"></div>

					<div class="layer a btn-row-dspl"></div>
					<div class="layer a btn-row-scrl"></div>

				</div>
			</div>
		)
	}	
}